import React from "react"
import styled from "styled-components"



const ScSvg = styled.svg`


`;


export const ScJqueryIcon = () => {
    return (
        <ScSvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 173.5 42.5" xmlSpace="preserve">
            <g>
                <path class="st0" fill='#131B28' d="M74.2,15.3L74.2,15.3l-1.1,3.9L74.2,15.3z M79.9,23.8l-1.2,0L79.9,23.8z M79.6,29.7L79.6,29.7l-7.3,0
                    L79.6,29.7z M81.1,23.9l-1.2,0L81.1,23.9z M80.2,29.2l1.3-4.9L80.2,29.2L80.2,29.2z M107.2,6.8L104.9,18
                    C104.9,18,107.2,6.8,107.2,6.8z M101.1,6.8l-1.8,8.4L101.1,6.8L101.1,6.8z"/>
                <path class="st0" fill='#131B28' d="M106.8,6.3h-5.1c-0.3,0-0.6,0.2-0.6,0.5l-1.8,8.4l-1.8,8.4c-0.1,0.3-0.3,0.5-0.6,0.5h-3.6
                    c-3.6,0-3.2-2.5-2.4-5.8l0-0.1l0.1-0.4l0-0.1l0.2-0.9l0.2-0.9c0.1-0.3,0.1-0.7,0.2-0.9l0.8-4l0.8-4c0.1-0.3-0.1-0.5-0.4-0.5h-5.2
                    c-0.3,0-0.6,0.2-0.6,0.5l-1.1,5.3l-1.1,5.3c0,0,0,0,0,0l-0.1,0.5c-1.3,5.9-1,11.5,6.1,11.7c0.1,0,0.2,0,0.2,0h11
                    c0.3,0,0.6-0.2,0.6-0.5l2.4-11.2l2.4-11.2C107.3,6.5,107.1,6.3,106.8,6.3L106.8,6.3z M127.1,24.1c0.3,0,0.5,0.2,0.4,0.5L127,27
                    M126.5,29.4L127,27L126.5,29.4L126.5,29.4z M125.9,29.9L125.9,29.9c-1.5,0-6,0-9.4,0C120.7,30,125.9,29.9,125.9,29.9z M113.5,21
                    c0-0.3,0.3-0.7,0.6-0.7l-0.6,0C113.2,23.1,113.5,21,113.5,21z"/>
                <path class="st0" fill='#131B28' d="M117,30C117,30,116.9,30,117,30C116.9,30,117,30,117,30z M127.1,24.1c0.3,0,0.5,0.2,0.4,0.5L127,27l-0.1,0.4
                    M119.4,29.9L119.4,29.9L119.4,29.9z M118.2,29.9L118.2,29.9L118.2,29.9z M117.8,30c0,0-0.1,0-0.1,0C117.7,30,117.8,30,117.8,30z
                    M117.4,30h-0.1H117.4z M118.1,29.9l-0.3,0L118.1,29.9z M119.4,29.9c-0.4,0-0.8,0-1.2,0C118.6,29.9,119,29.9,119.4,29.9z
                    M126.5,29.4l0.4-2L126.5,29.4L126.5,29.4z M117.7,30h-0.3H117.7z M116.9,30h-0.4H116.9z M117.3,30H117H117.3z M42.9,21l0.8-3.5
                    l-0.9,4l-0.9,4.1l0.8-3.6C42.7,21.7,42.8,21.3,42.9,21L42.9,21z M52.3,6.2h-5.6c-0.3,0-0.6,0.2-0.6,0.5l-0.5,2.3L45,11.4
                    c-0.1,0.3,0.1,0.5,0.4,0.5h5.6c0.3,0,0.6-0.2,0.6-0.5l0.5-2.3l0.5-2.3v0C52.8,6.5,52.6,6.2,52.3,6.2L52.3,6.2z M51.1,14L51.1,14
                    l-1.6,7.6L51.1,14L51.1,14z M34.9,39.1c0,0,2.8,0,4.6,0C38.1,39.2,35.9,39.1,34.9,39.1L34.9,39.1z M44.4,14l-0.8,3.5L44.4,14z"/>
                <path class="st0" fill='#131B28' d="M50.7,13.5h-5.6c-0.3,0-0.6,0.2-0.6,0.5l-0.8,3.5L42.9,21c-0.1,0.3-0.2,0.7-0.2,1l-0.8,3.6l-0.8,3.6
                    c-0.1,0.3-0.2,0.7-0.3,1c0,0-1.1,3.1-2.8,3.1c-0.1,0-0.1,0-0.2,0c-0.9,0-1.5,0-1.5,0h0c-0.3,0-0.6,0.2-0.6,0.5l-0.6,2.5l-0.6,2.5
                    c-0.1,0.3,0.1,0.5,0.4,0.5c1,0,3.2,0,4.6,0c4.4,0,6.7-2.4,8.2-9.5l1.7-8.1l1.6-7.6C51.2,13.7,51,13.5,50.7,13.5L50.7,13.5z
                    M130.7,29.7c-0.3,0-0.5-0.2-0.4-0.5l4.2-19.8 M136.6,29.2l1.7-8.5L136.6,29.2z"/>
                <path class="st0" fill='#131B28' d="M130.4,29.4c0-0.1,0-0.5,0-0.8l2-9.4l-2.1,9.9c-0.1,0.3,0.1,0.5,0.4,0.5h0.5C130.9,29.7,130.4,29.6,130.4,29.4
                    L130.4,29.4z M136.3,29.6c-0.2,0.1-0.5,0.1-0.8,0.1h0.5c0.3,0,0.6-0.2,0.6-0.5l0.1-0.5C136.6,28.9,136.4,29.5,136.3,29.6
                    L136.3,29.6z M137.5,24.9l0.8-3.7c0,0,0,0,0,0L137.5,24.9z M154.2,10.6l0.2,1c0.1,0.3-0.1,0.5-0.4,0.5 M136.7,28.7l0.8-3.7
                    L136.7,28.7z M153.9,9.7l0.2,1L153.9,9.7z M138.2,21.2c0.1-0.3,0.1-0.7,0.2-1l0.8-3.8l-0.9,4.3L138.2,21.2L138.2,21.2z M134.6,9
                    c-0.1,0.3-0.1,0.6-0.2,0.8l-2,9.4l2.1-9.9L134.6,9z"/>
                <path class="st0" fill='#131B28' d="M154.4,11.6l-0.2-1l-0.2-1l-0.1-0.5c-0.5-1.8-1.8-2.8-4.7-2.8l-4.5,0l-4.2,0h-4.7c-0.3,0-0.6,0.2-0.6,0.5h0
                    l-0.2,1L134.6,9l-0.1,0.3l-2.1,9.9l-2,9.4c-0.1,0.3-0.1,0.6,0,0.8c0,0.1,0.6,0.3,0.9,0.3h4.2c0.3,0,0.6-0.1,0.8-0.1
                    c0.2-0.1,0.4-0.6,0.4-0.9l0.8-3.7l0.8-3.7l0,0l0.1-0.5l0.9-4.3l0.8-3.8c0.1-0.3,0.3-0.5,0.6-0.5l13.4,0
                    C154.3,12.1,154.5,11.9,154.4,11.6L154.4,11.6z"/>
                <path class="st0" fill='#131B28' d="M171.6,2.9c-0.3,0-0.7,0-1,0h-4.3c-0.3,0-0.6,0.2-0.8,0.4L156,17.2c-0.2,0.2-0.3,0.2-0.4-0.1l-0.7-3.1
                    c-0.1-0.3-0.3-0.5-0.6-0.5h-6.1c-0.3,0-0.4,0.2-0.4,0.5l2.7,9.5c0.1,0.3,0.1,0.7,0,1l-1.2,4.6c-0.1,0.3,0.1,0.5,0.4,0.5h6
                    c0.3,0,0.6-0.2,0.6-0.5l1.2-4.6c0.1-0.3,0.3-0.7,0.4-0.9l15.3-20.4c0.2-0.2,0.1-0.4-0.2-0.4L171.6,2.9L171.6,2.9z M123.3,14.8
                    L123.3,14.8c0,0.3-0.3,0.5-0.6,0.5H115c-0.3,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0-0.1c0.7-2,2.2-3.2,5.1-3.2
                    C122.9,11.5,123.5,13.1,123.3,14.8L123.3,14.8z M121.1,5.9c-9.9,0-12.2,6-13.5,12.1c-1.3,6.2-1.2,11.9,9,11.9h0.4c0,0,0.1,0,0.1,0
                    h0.7c0,0,0.1,0,0.1,0l0.3,0h0.1c0.4,0,0.8,0,1.2,0h0c2.7,0,5.4,0,6.5,0c0.3,0,0.6-0.2,0.6-0.5l0.4-2L127,27l0.5-2.4
                    c0.1-0.3-0.1-0.5-0.4-0.5h-9.2c-3.7,0-4.7-1-4.3-3.8h14.7l0,0c0,0,0,0,0,0c0.2,0,0.4-0.2,0.5-0.4c0,0,0-0.1,0-0.1l0,0
                    C131,11.5,130.4,5.9,121.1,5.9L121.1,5.9L121.1,5.9z M74.3,14.8l-0.1,0.5l0,0l-1.1,3.9L72,23.2c-0.1,0.3-0.4,0.5-0.7,0.5h-5.9
                    c-4.5,0-5.5-3.5-4.5-8.6c1.1-5.3,3.2-8.5,7.6-8.9C74.6,5.7,75.8,9.9,74.3,14.8L74.3,14.8z M78.4,23.4c0,0,2.8-6.7,3.4-10.6
                    C82.6,7.5,80,0,70,0C60,0,55.6,7.2,54,15c-1.7,7.9,0.5,14.8,10.5,14.7l7.8,0l7.3,0c0.3,0,0.6-0.2,0.6-0.5l1.3-4.9
                    c0.1-0.3-0.1-0.5-0.4-0.5l-1.2,0l-1.2,0c-0.2,0-0.4-0.2-0.3-0.4C78.3,23.4,78.3,23.4,78.4,23.4L78.4,23.4L78.4,23.4z"/>
                <path class="st0" fill='#131B28' d="M127.9,18.9c0,0.2-0.2,0.3-0.3,0.3s-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3S127.9,18.7,127.9,18.9z"/>
                <path class="st1" fill='#0868AC' d="M2,10.1c-2.8,4-2.4,9.1-0.3,13.4c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1
                    c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.2,0.2,0.4c0,0.1,0.1,0.1,0.1,0.2
                    c0.1,0.2,0.2,0.3,0.3,0.5l0,0c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.3,0.3,0.4c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.3,0.3,0.4,0.5
                    c0.1,0.2,0.3,0.3,0.4,0.5c0,0,0,0,0,0C4.7,27.7,5,28,5.2,28.3c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.4,0.4c0,0,0.1,0,0.1,0.1
                    C6,29,6.2,29.1,6.3,29.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.3L7,29.7c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0.1,0.1,0.1
                    c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.3c0.1,0,0.1,0.1,0.2,0.1
                    c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.3
                    c0.2,0.1,0.4,0.2,0.6,0.2c0,0,0.1,0,0.1,0.1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0.1,0.4,0.1,0.6,0.2c0,0,0.1,0,0.1,0
                    c0.2,0.1,0.4,0.1,0.6,0.2c13.4,2.4,17.2-8,17.2-8c-3.3,4.2-9,5.4-14.5,4.1c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.5-0.2-0.7-0.2l-0.1,0
                    c-0.2-0.1-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.2c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.3
                    c-0.1-0.1-0.2-0.1-0.4-0.2c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.2c0,0-0.1-0.1-0.1-0.1c-0.2-0.1-0.4-0.2-0.6-0.3
                    c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1C10,26,9.8,25.9,9.7,25.8c-0.1,0-0.1-0.1-0.2-0.1
                    c-0.2-0.1-0.3-0.3-0.5-0.4c-0.1-0.1-0.3-0.2-0.4-0.4l0,0c-0.2-0.1-0.3-0.3-0.5-0.4c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3
                    c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.3-0.4-0.4l0,0C7,23.3,6.8,23.2,6.7,23c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.4
                    c0,0-0.1-0.1-0.1-0.1C6.1,22.3,6,22.1,5.8,22c-3-4.1-4.1-9.9-1.7-14.6"/>
                <path class="st1" d="M10.6,6.8c-2,2.9-1.9,6.7-0.3,9.8c0.3,0.5,0.6,1,0.9,1.5c0.3,0.4,0.6,0.9,1,1.3c0.1,0.2,0.3,0.3,0.4,0.5
                    c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.3,0.5,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.2,0.4,0.3,0.5,0.4c0,0,0.1,0.1,0.1,0.1
                    c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.2,0.4,0.3c0,0,0,0,0.1,0
                    c0.1,0.1,0.2,0.1,0.4,0.2c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.2,0.1,0.4,0.2,0.5,0.2c0,0,0.1,0,0.1,0
                    c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0,0.1,0,0.2,0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.4,0.1,0.6,0.2
                    c10.3,1.7,12.7-6.2,12.7-6.2c-2.1,3.1-6.3,4.6-10.7,3.4c-0.3-0.1-0.5-0.1-0.8-0.2c-0.2-0.1-0.4-0.1-0.6-0.2
                    c-0.1-0.1-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.4-0.2-0.5-0.2c-0.1,0-0.2-0.1-0.3-0.1l-0.2-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
                    c0,0-0.1,0-0.1,0c-0.1-0.1-0.3-0.2-0.4-0.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2-0.1-0.4-0.3-0.6-0.4
                    c0,0-0.1-0.1-0.1-0.1c-1.9-1.5-3.5-3.6-4.2-6c-0.8-2.5-0.6-5.2,0.7-7.5"/>
                <path class="st1" d="M17.8,4.3c-1.2,1.7-1.3,3.9-0.5,5.8c0.9,2,2.6,3.6,4.7,4.4c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0
                    c0.1,0,0.2,0.1,0.4,0.1c5.7,1.1,7.2-2.9,7.7-3.5c-1.4,1.9-3.6,2.4-6.4,1.7c-0.2-0.1-0.5-0.1-0.7-0.2c-0.3-0.1-0.5-0.2-0.8-0.3
                    c-0.5-0.2-1-0.5-1.4-0.9c-2.5-1.9-4-5.5-2.4-8.5 M81.9,38l-1.6,3.7h-1.8l0.1-3.4l-1.3,3.4h-1.8l0-3.7l0.2-0.9h1.2l-0.2,3.7l1.4-3.7
                    h1.7l-0.1,3.7l1.4-3.7h1.1L81.9,38z M84.8,39.1c0.2-0.7,0.1-0.9-0.3-0.9h-0.6L83.6,40L83,41.8h-1.1l0.9-4.1l0.1-0.6h1l0.1,0.3
                    c0,0,0.6-0.3,1.1-0.3c1.2,0,1.1,0.9,0.8,2.1L84.8,39.1z M88.2,36.5c-0.5,0-0.8-0.1-0.6-0.7c0.1-0.6,0.5-0.7,0.9-0.7
                    c0.5,0,0.8,0.1,0.6,0.7C89,36.4,88.7,36.5,88.2,36.5L88.2,36.5z M87.7,41.8h-1l0.2-1.8l0.4-1.7l-0.7-0.2l0.2-1h2L87.7,41.8
                    L87.7,41.8z M92.2,38.1l-1.3,0.2L90.6,40c-0.1,0.5-0.1,0.6,0.3,0.6h0.9l-0.2,0.9c0,0-0.8,0.2-1.1,0.2c-1.3,0-1.3-0.7-1.1-1.8
                    l0.4-1.7L89,38.1l0.2-1H90l0.4-1.2h1l-0.3,1.2h1.3L92.2,38.1L92.2,38.1z M95,38.1c-0.6,0-0.9,0.3-1.1,0.9l1.8-0.1
                    C95.7,38.5,95.7,38.1,95,38.1z M93.7,39.9c-0.1,0.6,0.1,0.8,0.9,0.8h1.8l-0.2,0.9c0,0-1.1,0.3-1.9,0.3c-2,0-2.1-1.2-1.8-2.4
                    c0.3-1.2,0.7-2.4,2.7-2.4c1.9,0,2,1.2,1.5,2.8H93.7L93.7,39.9z M100.9,41.8h-1l0.2-1.8l0.7-3.5l-0.7-0.2l0.2-1h2L100.9,41.8
                    L100.9,41.8z M104.7,38.1c-0.6,0-0.9,0.3-1.1,0.9l1.8-0.1C105.5,38.5,105.4,38.1,104.7,38.1z M103.5,39.9c-0.1,0.6,0.1,0.8,0.9,0.8
                    h1.8l-0.2,0.9c0,0-1.1,0.3-1.9,0.3c-2,0-2.1-1.2-1.8-2.4c0.3-1.2,0.7-2.4,2.7-2.4c1.9,0,2,1.2,1.5,2.8H103.5L103.5,39.9z
                    M111.3,38.2c0,0-1.3,0-1.7,0c-0.8,0-0.9,0.2-0.9,0.5l0,0.2l2.5,0.2c0,0,0,0.8-0.1,1.2c-0.1,0.7-0.9,1.5-2.3,1.5
                    c-0.7,0-1.8-0.3-1.8-0.3l0.2-0.9h1.7c0.8,0,0.9-0.2,0.9-0.4l0.1-0.3l-2.4-0.2c0,0-0.1-0.6,0-1.2c0.2-0.8,0.9-1.5,2.3-1.5
                    c0.7,0,1.8,0.2,1.8,0.2L111.3,38.2L111.3,38.2z M115.9,38.2c0,0-1.3,0-1.7,0c-0.8,0-0.9,0.2-0.9,0.5l0,0.2l2.5,0.2
                    c0,0,0,0.8-0.1,1.2c-0.1,0.7-0.9,1.5-2.3,1.5c-0.7,0-1.8-0.3-1.8-0.3l0.2-0.9h1.7c0.8,0,0.9-0.2,0.9-0.4l0.1-0.3l-2.4-0.2
                    c0,0-0.1-0.6,0-1.2c0.2-0.8,0.9-1.5,2.3-1.5c0.7,0,1.8,0.2,1.8,0.2L115.9,38.2L115.9,38.2z M117.3,42.5h-0.9l0-0.7
                    c-0.3,0-0.5-0.3-0.4-0.7c0.1-0.6,0.5-0.7,0.9-0.7c0.5,0,0.8,0.1,0.6,0.7L117.3,42.5z M123.4,38.2c-0.7,0-0.9,0.4-1.1,1.2
                    c-0.2,0.8-0.1,1.2,0.6,1.2h0.9l0.5-2.4H123.4z M125,41.2l-0.1,0.6l-1,0l-0.1-0.3c0,0-0.8,0.4-1.4,0.4c-1.5,0-1.5-1.2-1.3-2.4
                    c0.3-1.2,0.8-2.4,2.3-2.4c0.5,0,1.2,0.3,1.2,0.3l0.6-2h1L125,41.2L125,41.2z M128.8,38.2c-0.9,0-1.2,0.5-1.3,1.2
                    c-0.1,0.7-0.1,1.2,0.8,1.2c0.9,0,1.2-0.5,1.3-1.2C129.7,38.7,129.7,38.2,128.8,38.2L128.8,38.2z M128,41.8c-1.9,0-2-1.2-1.8-2.4
                    c0.3-1.2,0.9-2.4,2.8-2.4c1.9,0,2,1.2,1.8,2.4C130.5,40.7,129.9,41.8,128,41.8L128,41.8z M138.7,41.8l0.2-1.8l0.1-0.5
                    c0.1-0.7,0.2-1.2-0.5-1.2h-0.6c0,0.4,0,0.8-0.1,1.2l-0.5,2.3h-1.1l0.2-1.8l0.1-0.5c0.1-0.7,0.2-1.2-0.5-1.2h-0.5l-0.8,3.5h-1.2
                    l1-4.7h1l0.1,0.3c0,0,0.7-0.3,1.2-0.3c0.4,0,0.7,0.2,0.8,0.4c0.2-0.1,0.9-0.4,1.3-0.4c1.5,0,1.4,1.2,1.2,2.4l-0.5,2.3L138.7,41.8
                    L138.7,41.8L138.7,41.8z M143.5,38.2c-0.9,0-1.2,0.5-1.3,1.2c-0.1,0.7-0.1,1.2,0.8,1.2c0.9,0,1.2-0.5,1.3-1.2
                    C144.5,38.7,144.4,38.2,143.5,38.2L143.5,38.2z M142.7,41.8c-1.9,0-2-1.2-1.8-2.4c0.3-1.2,0.9-2.4,2.8-2.4c1.9,0,2,1.2,1.8,2.4
                    C145.3,40.7,144.6,41.8,142.7,41.8z M148.7,39.1c0.2-0.7,0.1-0.9-0.3-0.9h-0.6l-0.4,1.7l-0.6,1.8h-1.1l0.9-4.1l0.1-0.6h1l0.1,0.3
                    c0,0,0.6-0.3,1.1-0.3c1.2,0,1.1,0.9,0.8,2.1L148.7,39.1L148.7,39.1z M152.8,38.1c-0.6,0-0.9,0.3-1.1,0.9l1.8-0.1
                    C153.6,38.5,153.5,38.1,152.8,38.1L152.8,38.1z M151.6,39.9c-0.1,0.6,0.1,0.8,0.9,0.8h1.8l-0.2,0.9c0,0-1.1,0.3-1.9,0.3
                    c-2,0-2.1-1.2-1.8-2.4c0.3-1.2,0.7-2.4,2.7-2.4c1.9,0,2,1.2,1.5,2.8H151.6L151.6,39.9z M155.6,41.8c-0.5,0-0.8-0.1-0.6-0.7
                    c0.1-0.6,0.5-0.7,0.9-0.7c0.4,0,0.8,0.1,0.6,0.7C156.4,41.7,156.1,41.8,155.6,41.8z"/>
            </g>
        </ScSvg>

    )
}

