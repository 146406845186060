import React from "react"
import styled from "styled-components"



const ScSvg = styled.svg`


`;


export const ScHtmlIcon = () => {
    return (
        <ScSvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 85 85">
            <path class="st0" fill = "#E44D26" d="M72.6,16.7l-5.5,61.5L42.5,85l-24.6-6.8l-5.5-61.5H72.6L72.6,16.7z"/>
            <path class="st1" fill = "#F16529" d="M42.5,79.8l19.9-5.5l4.7-52.5H42.5V79.8z"/>
            <path class="st2" fill = "#EBEBEB" d="M31.8,36.8h10.7v-7.5H23.6l0.2,2l1.9,20.8h16.9v-7.5h-10L31.8,36.8z M33.5,55.8H26L27,67.7L42.5,72l0,0v-7.8
                l0,0l-8.4-2.3L33.5,55.8L33.5,55.8z"/>
            <path d="M18,0h3.8v3.8h3.5V0h3.8v11.5h-3.8V7.6h-3.5v3.8H18L18,0L18,0z M34.2,3.8h-3.4V0h10.6v3.8H38v7.7h-3.8L34.2,3.8L34.2,3.8
                L34.2,3.8z M43.1,0h4l2.5,4L52,0h4v11.5h-3.8V5.8l-2.6,4.1h-0.1l-2.6-4.1v5.7h-3.7V0z M57.9,0h3.8v7.7h5.4v3.8h-9.2L57.9,0L57.9,0z
                "/>
            <path class="st3" fill = "#FFFFFF" d="M42.5,52.1h9.3l-0.9,9.8l-8.4,2.3V72l15.5-4.3l0.1-1.3l1.8-19.8l0.2-2H42.5V52.1L42.5,52.1z M42.5,36.8
                L42.5,36.8l18.2,0l0.2-1.7l0.3-3.8l0.2-2H42.5V36.8L42.5,36.8z"/>
        </ScSvg>

    )
}

