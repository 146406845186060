import React from "react"
import styled from "styled-components"



const ScSvg = styled.svg`


`;


export const ScCssIcon = () => {
    return (
        <ScSvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 85 85" xmlSpace="preserve">
            <g>
                <path class="st0" fill = '#2062AF' d="M67.1,78.4L42.5,85l-24.6-6.6l-5.7-61.6h60.6L67.1,78.4z"/>
                <path class="st1" fill = '#3C9CD7' d="M42.5,21.8v57.9l0.1,0l19.9-5.4l4.6-52.6L42.5,21.8L42.5,21.8z"/>
                <path d="M28.5,7.8V4.1h5.3v-4h-9.4v11.3h9.4V7.8H28.5z M43.5,4.1h3.7v-4h-9.4v4c1.3,1.3,1.9,1.9,3.7,3.7c-1.1,0-3.7,0-3.7,0v3.7
                    h9.4V7.8L43.5,4.1L43.5,4.1z M56.8,4.1h3.7v-4h-9.4v4c1.3,1.3,1.9,1.9,3.7,3.7c-1.1,0-3.7,0-3.7,0v3.7h9.4V7.8L56.8,4.1L56.8,4.1z"
                    />
                <path class="st2" fill = '#FFFFFF' d="M42.5,37.2l-17.6,7.3l0.6,7.3l17-7.3l18.1-7.8l0.8-7.4L42.5,37.2L42.5,37.2z"/>
                
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-1038.6777" y1="-1562.6023" x2="-1038.6777" y2="-1565.7496" gradientTransform="matrix(4.6418 0 0 4.6418 4855 7305.0796)">
                    <stop  offset="0.387" />
                    <stop  offset="1" />
                </linearGradient>
                <path class="st3" fill='url(#SVGID_1_)}' d="M24.9,44.5l0.6,7.3l17-7.3v-7.3L24.9,44.5z"/>
                
                    <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-1034.7451" y1="-1564.1721" x2="-1034.7451" y2="-1567.4437" gradientTransform="matrix(4.6418 0 0 4.6418 4855 7305.0796)">
                    <stop  offset="0.387" />
                    <stop  offset="1"/>
                </linearGradient>
                <path class="st4" fill = 'url(#SVGID_2_)' d="M61.4,29.3l-18.9,7.9v7.3l18.1-7.8L61.4,29.3z"/>
                
                    <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-1040.5706" y1="-1561.1985" x2="-1033.0112" y2="-1561.1985" gradientTransform="matrix(4.6418 0 0 4.6418 4855 7305.0796)">
                    <stop  offset="0" />
                    <stop  offset="1" />
                </linearGradient>
                <path class="st5" fill = 'url(#SVGID_3_)' d="M24.9,44.5l0.6,7.3l26.1,0.1L51,61.6L42.4,64l-8.3-2.1l-0.5-6H26l1,11.6l15.5,4.6L58,67.6l2-23.1H24.9z"/>
                <path class="st6" style = {Object.assign( {opacity:' 5.000000e-]02'},  {enableBackground: 'new'})}  d="M42.5,44.5H24.9l0.6,7.3l17,0.1L42.5,44.5L42.5,44.5z M42.5,64L42.5,64l-8.4-2.1l-0.5-6h-7.7l1,11.6l15.5,4.6
                    V64z"/>
                
                    <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-1040.9185" y1="-1566.6428" x2="-1032.7113" y2="-1566.6428" gradientTransform="matrix(4.6418 0 0 4.6418 4855 7305.0796)">
                    <stop  offset="0" />
                    <stop  offset="1" />
                </linearGradient>
                <path class="st7" fill='url(#SVGID_4_)' d="M23.3,29.3h38.1l-0.8,7.4H24.2L23.3,29.3L23.3,29.3z"/>
                <path class="st6" style = {Object.assign( {opacity:' 5.000000e-]02'},  {enableBackground: 'new'})}  d="M42.5,29.3H23.3l0.9,7.4h18.3L42.5,29.3L42.5,29.3z"/>
            </g>
        </ScSvg>

    )
}

