import React from "react"
import styled from "styled-components"



const ScSvg = styled.svg`


`;


export const ScNodeIcon = ()=> {
    return(
<ScSvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 173.5 42.5"  xmlSpace="preserve">

<g>
<g>
   <path class="st0" 	fill='#83CD29' d="M149.5,15c-0.4,0-0.7,0.1-1,0.3l-9.9,5.7c-0.6,0.4-1,1.1-1,1.8v11.4c0,0.7,0.4,1.4,1,1.8l2.6,1.5
       c1.3,0.6,1.7,0.6,2.3,0.6c1.9,0,2.9-1.1,2.9-3.1V23.7c0-0.2-0.1-0.3-0.3-0.3h-1.2c-0.2,0-0.3,0.1-0.3,0.3V35c0,0.9-0.9,1.7-2.4,1
       l-2.7-1.6c-0.1-0.1-0.2-0.1-0.2-0.3V22.8c0-0.1,0.1-0.2,0.2-0.3l9.9-5.7c0.1-0.1,0.2-0.1,0.3,0l9.9,5.7c0.1,0.1,0.2,0.2,0.2,0.3
       v11.4c0,0.1-0.1,0.2-0.1,0.3l-9.9,5.7c-0.1,0.1-0.2,0.1-0.3,0l-2.5-1.5c-0.1,0-0.2,0-0.3,0c-0.7,0.4-0.8,0.4-1.5,0.7
       c-0.2,0.1-0.4,0.1,0.1,0.4l3.3,2c0.3,0.2,0.7,0.3,1,0.3c0.4,0,0.7-0.1,1-0.3l9.9-5.7c0.6-0.4,1-1.1,1-1.8V22.8
       c0-0.7-0.4-1.4-1-1.8l-9.9-5.7C150.2,15.1,149.9,15,149.5,15L149.5,15z"/>
   <path class="st0" fill='#83CD29' d="M152.2,23.1c-2.8,0-4.5,1.2-4.5,3.2c0,2.2,1.7,2.8,4.4,3c3.2,0.3,3.5,0.8,3.5,1.4c0,1.1-0.9,1.6-3,1.6
       c-2.6,0-3.2-0.7-3.4-2c0-0.1-0.1-0.3-0.3-0.3h-1.3c-0.2,0-0.3,0.1-0.3,0.3c0,1.7,0.9,3.6,5.2,3.6c3.1,0,4.9-1.2,4.9-3.4
       c0-2.1-1.5-2.7-4.5-3.1c-3.1-0.4-3.4-0.6-3.4-1.3c0-0.6,0.2-1.4,2.5-1.4c2,0,2.8,0.4,3.1,1.8c0,0.1,0.1,0.2,0.3,0.2h1.3
       c0.1,0,0.2,0,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2C156.9,24.3,155.3,23.1,152.2,23.1L152.2,23.1z"/>
</g>
<path class="st1" 	fill = '#404137' d="M91.3,0c-0.1,0-0.2,0-0.3,0.1c-0.2,0.1-0.3,0.3-0.3,0.5v16.1c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.3,0.1-0.4,0
   l-2.6-1.5c-0.4-0.2-0.9-0.2-1.3,0l-10.5,6.1c-0.4,0.2-0.6,0.7-0.6,1.1v12.1c0,0.5,0.2,0.9,0.6,1.1L86.1,42c0.4,0.2,0.9,0.2,1.3,0
   L97.8,36c0.4-0.2,0.6-0.6,0.6-1.1V4.7c0-0.5-0.2-0.9-0.7-1.1l-6.3-3.5C91.5,0,91.4,0,91.3,0L91.3,0z M23.7,15.1
   c-0.2,0-0.4,0-0.6,0.1l-10.5,6.1C12.2,21.6,12,22,12,22.4l0,16.3c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.1,0.6,0l6.2-3.6
   c0.4-0.2,0.7-0.6,0.7-1.1V27c0-0.5,0.2-0.9,0.6-1.1l2.7-1.5c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2l2.7,1.5
   c0.4,0.2,0.6,0.6,0.6,1.1v7.6c0,0.5,0.3,0.9,0.7,1.1l6.2,3.6c0.2,0.1,0.4,0.1,0.6,0c0.2-0.1,0.3-0.3,0.3-0.5l0-16.3
   c0-0.5-0.2-0.9-0.6-1.1l-10.5-6.1c-0.2-0.1-0.4-0.1-0.6-0.1H23.7z M118.1,15.3c-0.2,0-0.4,0.1-0.6,0.2l-10.5,6.1
   c-0.4,0.2-0.6,0.6-0.6,1.1v12.1c0,0.5,0.3,0.9,0.7,1.1l10.4,5.9c0.4,0.2,0.9,0.2,1.2,0l6.3-3.5c0.2-0.1,0.3-0.3,0.3-0.5
   c0-0.2-0.1-0.4-0.3-0.5l-10.6-6.1c-0.2-0.1-0.3-0.3-0.3-0.6v-3.8c0-0.2,0.1-0.4,0.3-0.5l3.3-1.9c0.2-0.1,0.4-0.1,0.6,0l3.3,1.9
   c0.2,0.1,0.3,0.3,0.3,0.5v3c0,0.2,0.1,0.4,0.3,0.5c0.2,0.1,0.4,0.1,0.6,0l6.3-3.7c0.4-0.2,0.6-0.6,0.6-1.1v-2.9
   c0-0.5-0.2-0.9-0.6-1.1l-10.4-6.1C118.5,15.3,118.3,15.3,118.1,15.3L118.1,15.3z M86.7,24.3c0.1,0,0.1,0,0.2,0l3.6,2.1
   c0.1,0.1,0.2,0.2,0.2,0.3v4.2c0,0.1-0.1,0.2-0.2,0.3l-3.6,2.1c-0.1,0.1-0.2,0.1-0.3,0l-3.6-2.1c-0.1-0.1-0.2-0.2-0.2-0.3v-4.2
   c0-0.1,0.1-0.2,0.2-0.3l3.6-2.1C86.6,24.3,86.6,24.3,86.7,24.3L86.7,24.3L86.7,24.3z"/>
<path class="st0"  fill='#83CD29'd="M55.2,15.2c-0.2,0-0.4,0.1-0.6,0.2l-10.5,6c-0.4,0.2-0.6,0.7-0.6,1.1v12.1c0,0.5,0.2,0.9,0.6,1.1l10.5,6.1
   c0.4,0.2,0.9,0.2,1.3,0l10.5-6.1c0.4-0.2,0.6-0.6,0.6-1.1V22.5c0-0.5-0.2-0.9-0.6-1.1l-10.5-6C55.7,15.3,55.5,15.2,55.2,15.2
   L55.2,15.2z M118,26c0,0-0.1,0-0.1,0l-2,1.2c-0.1,0-0.1,0.1-0.1,0.2v2.3c0,0.1,0.1,0.2,0.1,0.2l2,1.2c0.1,0,0.2,0,0.2,0l2-1.2
   c0.1,0,0.1-0.1,0.1-0.2v-2.3c0-0.1-0.1-0.2-0.1-0.2l-2-1.2C118.1,26,118.1,26,118,26L118,26z"/>
</g>
</ScSvg>

    )
}

